<template>
  <div class="event-details py-8">
    <EventAppbar />

    <v-row class="mx-4">
      <v-col cols="12" class="ma-0 pa-0">
        <ViewingTitle :title="displayName" class="event-details__title" />

        <div class="event-details__submenu">
          <EventDuplicateButton v-if="hasDuplicatePermission" :event="event" />
          <DeleteOrRestore
            v-if="hasDeletePermission"
            :model="event"
            :max-width="500"
            name="event"
            redirect="/event/dashboard"
            :delete-path="`/events/${event?.id}`"
          />
        </div>

        <div class="d-flex mb-6">
          <v-tabs v-model="tabIndex" hide-slider class="event-info-tabs">
            <v-tab v-for="(item, key) in tabItems" :key="key">
              {{ item.tab }}
            </v-tab>

            <v-tab-item class="col-sm-12 col-xs-12 mt-6 pa-0">
              <v-divider class="event-details__divider"></v-divider>

              <EventForm class="event-details__form" :event="event" />
            </v-tab-item>
          </v-tabs>
        </div>
      </v-col>
    </v-row>

    <Loading :value="loading" />
  </div>
</template>
<script>
/**
 * ==================================================================================
 * View Event Event
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import ViewingTitle from '@/components/common/ViewingTitle'
import EventForm from '@/views/Home/Event/Events/components/EventForm'
import EventAppbar from '@/views/Home/Event/Events/components/EventAppbar'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Loading from '@/components/common/Loading'
import DeleteOrRestore from '@/components/fields/DeleteOrRestore'
import EventDuplicateButton from '@/views/Home/Event/Events/components/EventDuplicateButton'
import { isReadOnlyRole, validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'

const TABS = [['events'], ['attendees']]

export default {
  name: 'EventDetails',

  components: {
    ViewingTitle,
    EventAppbar,
    EventForm,
    Loading,
    DeleteOrRestore,
    EventDuplicateButton,
  },

  mixins: [SnackbarMixin, ErrorHandlerMixin],

  data() {
    return {
      loading: false,
      tabIndex: 0,
      tabItems: [{ tab: 'Info' }],
    }
  },

  computed: {
    ...mapState({
      event: (state) => state.event.eventDetails,
      permissions: (state) => state.auth.permissions,
      role: (state) => state.auth.role,
    }),

    displayName() {
      return this.event ? this.event.title : null
    },

    hasDeletePermission() {
      return validatePermissions([PERMISSION.EVENTS_DELETE], this.permissions)
    },

    hasDuplicatePermission() {
      return !isReadOnlyRole(this.role)
    },
  },

  watch: {
    $route() {
      this.getEvent()
    },

    tabIndex(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateTab(newValue)
      }
    },
  },

  methods: {
    ...mapActions({
      getEventDetails: 'event/getEventDetails',
    }),

    ...mapMutations({
      clearEventDetails: 'event/clearEventDetails',
    }),

    init() {
      const query = this.getTabIndex()
      if (!isNaN(parseInt(query))) {
        this.tabIndex = parseInt(query)
      } else {
        this.tabIndex = this.getTabIndexByLabel()
      }
    },

    updateTab(value) {
      const tab = this.isIndexValid(value) ? value : -1
      history.pushState(
        {},
        null,
        `${this.$route.path}${tab >= 0 ? `?tab=${tab}` : ''}`
      )
    },

    async getEvent() {
      this.loading = true
      await this.getEventDetails(this.$route.params.id)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {
          this.loading = false
        })
    },

    getTabIndex() {
      return this.$route.query.tab || ''
    },

    getTabIndexByLabel() {
      const value = this.getTabIndex().toLowerCase()
      for (const tab in TABS) {
        if (TABS[tab].includes(value)) {
          return parseInt(tab)
        }
      }

      return -1
    },

    isIndexValid(value) {
      return value >= 0 && value <= 1
    },
  },

  created() {
    this.init()
    this.getEvent()
  },

  destroyed() {
    this.clearEventDetails()
  },
}
</script>

<style lang="scss" scoped>
.event-details {
  &__title {
    padding-right: 10px;
  }

  &__submenu {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  &__submenu,
  &__divider {
    max-width: 500px;
  }

  @media (max-width: 768px) {
    &__divider {
      max-width: 100%;
    }
  }
}
</style>
